import React from 'react';

export const Facebook = () => (
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 0H2C0.895 0 0 0.895 0 2V20C0 21.105 0.895 22 2 22H12V13H9V10H12V8.389C12 5.339 13.486 4 16.021 4C17.235 4 17.877 4.09 18.181 4.131V7H16.452C15.376 7 15 7.568 15 8.718V10H18.154L17.726 13H15V22H20C21.105 22 22 21.105 22 20V2C22 0.895 21.104 0 20 0Z" fill="white"/>
</svg>
);

export const Linkdin = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 0H2C0.895 0 0 0.895 0 2V20C0 21.105 0.895 22 2 22H20C21.105 22 22 21.105 22 20V2C22 0.895 21.105 0 20 0ZM6.954 18H4.004V8.508H6.954V18ZM5.449 7.151C4.498 7.151 3.729 6.38 3.729 5.431C3.729 4.482 4.499 3.712 5.449 3.712C6.397 3.712 7.168 4.483 7.168 5.431C7.168 6.38 6.397 7.151 5.449 7.151ZM18.004 18H15.056V13.384C15.056 12.283 15.036 10.867 13.523 10.867C11.988 10.867 11.752 12.066 11.752 13.304V18H8.804V8.508H11.634V9.805H11.674C12.068 9.059 13.03 8.272 14.465 8.272C17.452 8.272 18.004 10.238 18.004 12.794V18Z" fill="white"/>
</svg>
);

export const Insta = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 0C2.91624 0 0 2.91624 0 6.5V15.5C0 19.0838 2.91624 22 6.5 22H15.5C19.0838 22 22 19.0838 22 15.5V6.5C22 2.91624 19.0838 0 15.5 0H6.5ZM6.5 1H15.5C18.5432 1 21 3.45676 21 6.5V15.5C21 18.5432 18.5432 21 15.5 21H6.5C3.45676 21 1 18.5432 1 15.5V6.5C1 3.45676 3.45676 1 6.5 1ZM17 4C16.7348 4 16.4804 4.10536 16.2929 4.29289C16.1054 4.48043 16 4.73478 16 5C16 5.26522 16.1054 5.51957 16.2929 5.70711C16.4804 5.89464 16.7348 6 17 6C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4ZM11 5.5C7.96836 5.5 5.5 7.96836 5.5 11C5.5 14.0316 7.96836 16.5 11 16.5C14.0316 16.5 16.5 14.0316 16.5 11C16.5 7.96836 14.0316 5.5 11 5.5ZM11 6.5C13.4912 6.5 15.5 8.5088 15.5 11C15.5 13.4912 13.4912 15.5 11 15.5C8.5088 15.5 6.5 13.4912 6.5 11C6.5 8.5088 8.5088 6.5 11 6.5Z" fill="white"/>
  </svg>
);