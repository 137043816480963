import React, { Suspense, lazy, useEffect } from 'react';
import './App.css';
import Footer from './Footer/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './utils.scss';
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './ScrollToTop';
import Navbar from './Navbar/Navbar';

// Lazy load components
const Banner = lazy(() => import('./Banner/Banner'));
const Bubble = lazy(() => import('./Banner/Bubble.jsx'));
const About = lazy(() => import('./pages/About/About'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const OurWork = lazy(() => import('./pages/OurWorks/OurWork'));
const Seo = lazy(() => import('./pages/Seo/Seo'));
const Add = lazy(() => import('./pages/Advertizement/Add'));
const User = lazy(() => import('./pages/UserExperience/User'));
const Development = lazy(() => import('./pages/Development/Development'));
const CaseStudy = lazy(() => import('./pages/CaseStudy/CaseStudy'));
const Manegement = lazy(() => import('./pages/Management/Manegement'));
const GoogleAds = lazy(() => import('./pages/GoogleAds/GoogleAds'));
const Bussiness = lazy(() => import('./pages/Bussiness/Bussiness'));
const ThankYou = lazy(() => import('./pages/ThankYou/ThankYou'));
const Privacy = lazy(() => import('./pages/Privacy/Privacy'));
const Terms = lazy(() => import('./pages/Terms/Terms'));
const Blog = lazy(() => import('./pages/Blog/Blog'));
const Singular = lazy(() => import('./pages/Singular/Singular'));
const Grow = lazy(() => import('./pages/Grow/Grow'));
const TellUs = lazy(() => import('./pages/Bussiness/components/TellUs/TellUs'));
const LandingPage = lazy(() => import('./pages/WebDesign/LandingPage/LandingPage'));
const CategoryPage = lazy(() => import('./pages/CategoryPage/CategoryPage'));
const Screen = lazy(() => import('./pages/WebDesign/component/Screen/Screen'));
const Careers = lazy(() => import('./pages/Careers/Careers'));
const CareerSingle = lazy(() => import('./pages/Careers/singleCareer/singleCareer.jsx'));

function App() {
  const location = useLocation();
  const hideHeader = location.pathname.includes('webDesign');
  const hideHeader1 = location.pathname.includes('grow');

  useEffect(()=>{
    document.addEventListener("DOMContentLoaded", function() {
      let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    
      if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {
              let lazyImage = entry.target;
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.classList.remove("lazy");
              lazyImageObserver.unobserve(lazyImage);
            }
          });
        });
    
        lazyImages.forEach(function(lazyImage) {
          lazyImageObserver.observe(lazyImage);
        });
      } else {
        // Fallback for older browsers
        let lazyLoadThrottleTimeout;
        function lazyLoad() {
          if(lazyLoadThrottleTimeout) {
            clearTimeout(lazyLoadThrottleTimeout);
          }
    
          lazyLoadThrottleTimeout = setTimeout(function() {
            let scrollTop = window.pageYOffset;
            lazyImages.forEach(function(img) {
              if(img.offsetTop < (window.innerHeight + scrollTop)) {
                img.src = img.dataset.src;
                img.classList.remove('lazy');
              }
            });
            if(lazyImages.length === 0) {
              document.removeEventListener("scroll", lazyLoad);
              window.removeEventListener("resize", lazyLoad);
              window.removeEventListener("orientationChange", lazyLoad);
            }
          }, 20);
        }
    
        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationChange", lazyLoad);
      }
    });    
  },[])

  return (
    <>
      <ToastContainer />
      {!hideHeader && !hideHeader1 && <Navbar />}
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="/bubble" element={<Bubble />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/work" element={<OurWork />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/social-media-advertisement" element={<Add />} />
          <Route path="/brand-and-design" element={<User />} />
          <Route path="/web-design-and-development" element={<Development />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers/:slug" element={<CareerSingle />} />
          <Route path="/case-study/:slug" element={<CategoryPage />} />
          <Route path="/case-studies" element={<CaseStudy />} />
          <Route path="/social-media-management" element={<Manegement />} />
          <Route path="/google-ads-manangement" element={<GoogleAds />} />
          <Route path="/it-managed-services-and-leading-it-support" element={<Bussiness />} />
          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/blog" element={<Blog />} />
          <Route path={`/:slug`} element={<Singular />} />
          <Route path="/tellus" element={<TellUs />} />
          <Route path="/grow" element={<Grow />} />
          <Route path="/webDesign" element={<LandingPage />} />

          
        </Routes>
      </Suspense>
      {!hideHeader && !hideHeader1 && <Footer />}
    </>
  );
}

export default App;
