import React, { useEffect, useState } from 'react'
import './navbar.scss'
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { BackArrow, BusinessIcon, CaseStudiesIcon, ChatIcon, ExperienceIcon, HomeIcon, MainLogo,  MenuBarClose, MenuBarOpen, MobileLogo, PeopleIcon, SpeakerIcon, WorkIcon } from '../assets/svgIcons';
const MobileMenu = ({handleClosePopup}) => {
    const [activeMenu, setActiveMenu] = useState(null);
    const addClassActive = (menu) => {
        setActiveMenu(menu)
    };
    return (<>
        <div className='mobileMenu'>
        </div>
        <div className={`mobileMenuItems ${activeMenu === null ? '' : 'hide'}`}>
            <div className='menuItems'>
                <NavLink to='/' onClick={handleClosePopup}><HomeIcon />Home</NavLink>
            </div>
            <div className='menuItems'>
                <NavLink to='/about' onClick={handleClosePopup}><PeopleIcon />About us</NavLink>
            </div>
            <div className='menuItems'>
                <NavLink  to='/careers' onClick={handleClosePopup}><WorkIcon />Careers</NavLink>
            </div>
            {/* <a className='menuItems' href='/#' onClick={() => addClassActive(`subMenuMobile2`)}><SpeakerIcon />Careers</a><br /><br /> */}
            <div className='menuItems'>
                <NavLink to='/case-studies' onClick={handleClosePopup}><CaseStudiesIcon />Case Studies</NavLink>
            </div>
            {/* <a className='menuItems' href='/#' onClick={() => addClassActive(`subMenuMobile3`)}><WorkIcon />Case Studies</a><br /><br /> */}
            <div className='menuItems' onClick={() => addClassActive(`subMenuMobile4`)}><SpeakerIcon />Digital Performance</div>
            <div className='menuItems' onClick={() => addClassActive(`subMenuMobile5`)}><ExperienceIcon />User Experience</div>
            <div className='menuItems'>
             <NavLink  to='/it-managed-services-and-leading-it-support' onClick={handleClosePopup}><BusinessIcon />Business It</NavLink>
            </div>
            {/* <a className='menuItems' href='/#' onClick={() => addClassActive(`subMenuMobile6`)}><ExperienceIcon />business It</a><br /><br /> */}
        </div>
        {/* <p className={`mobileSubMenuItems ${activeMenu === 'subMenuMobile1' ? 'show' : ''}`}>
            <a className='menuItemBack' href='/#' onClick={() => addClassActive(null)}><BackArrow /></a><br /><br />
            <a className='menuItem' href='/#' ><PeopleIcon />Careers</a><br /><br />
            <a className='subMenuItems' href='/case'>Google Advertising</a><br /><br />
            <a className='subMenuItems' href='/#'>Google Advertising</a><br /><br />
            <a className='subMenuItems' href='/#'>Google Advertising</a><br />
        </p> */}
        {/* <p className={`mobileSubMenuItems ${activeMenu === 'subMenuMobile2' ? 'show' : ''}`}>
            <a className='menuItemBack' href='/#' onClick={() => addClassActive(null)}><BackArrow /></a><br /><br />
            <a className='menuItem' href='/#' ><SpeakerIcon />Services</a><br /><br />
            <a className='subMenuItems' href='/#'>Google Advertising</a><br /><br />
            <a className='subMenuItems' href='/#'>Google Advertising</a><br /><br />
            <a className='subMenuItems' href='/#'>
                2 Advertising</a><br />
        </p> */}

        <p className={`mobileSubMenuItems ${activeMenu === 'subMenuMobile3' ? 'show' : ''}`}>
            <a className='menuItemBack' href='/#' onClick={() => addClassActive(null)}><BackArrow /></a><br /><br/>
            <div>

                <a className='menuItem' href='/#' ><WorkIcon />Services</a><br /><br/>
                <a className='subMenuItems' href='/#'>Google Advertising</a><br /><br />
                <a className='subMenuItems' href='/#'>Google Advertising</a><br /> <br /> 
                <a className='subMenuItems' href='/#'>3 Advertising</a><br />
            </div>
        </p>

        <p className={`mobileSubMenuItems ${activeMenu === 'subMenuMobile4' ? 'show' : ''}`}>
            <a className='menuItemBack'  onClick={() => addClassActive(null)}><BackArrow /></a><br /><br />
            <a className='menuItem'  ><SpeakerIcon />Digital Performance</a><br /><br />
            <NavLink className='subMenuItems' onClick={()=> { handleClosePopup(); setActiveMenu(null)}} to='/google-ads-manangement' >Google Advertising</NavLink><br /><br />
            <NavLink className='subMenuItems' onClick={()=> { handleClosePopup(); setActiveMenu(null)}} to='/social-media-management' >Social Media Management</NavLink><br /><br />
            <NavLink className='subMenuItems' onClick={()=> { handleClosePopup(); setActiveMenu(null)}} to='/social-media-advertisement' >Social Media Advertising</NavLink><br /><br />
            <NavLink className='subMenuItems' onClick={()=> { handleClosePopup(); setActiveMenu(null)}} to='/seo' >Search Engine Optimisation</NavLink><br /><br />
        </p>

        <p className={`mobileSubMenuItems ${activeMenu === 'subMenuMobile5' ? 'show' : ''}`}>
            <a className='menuItemBack'  onClick={() => addClassActive(null)}><BackArrow /></a><br /><br />
            <a className='menuItem'  ><ExperienceIcon />User Experience</a><br /><br />
            <NavLink className='subMenuItems' to='/web-design-and-development' onClick={()=> { handleClosePopup(); setActiveMenu(null)}}>Website Design & Development</NavLink><br /><br />
            <NavLink className='subMenuItems' to='/brand-and-design' onClick={()=> { handleClosePopup(); setActiveMenu(null)}}>Branding & Logo Design</NavLink><br /><br />
            {/* <a className='subMenuItems' href='/web-design-and-development' onClick={handleClosePopup} >Website Design & Development</a>
            <a className='subMenuItems' href='/user' onClick={handleClosePopup}>Branding & Logo Design</a> */}
            
        </p>

        <p className={`mobileSubMenuItems ${activeMenu === 'subMenuMobile6' ? 'show' : ''}`}>
            <a className='menuItemBack' href='/#' onClick={() => addClassActive(null)}><BackArrow /></a><br /><br />
            <a className='menuItem' href='/#' ><ExperienceIcon />Services</a><br /><br/>
            <a className='subMenuItems' href='/#'>Google Advertising</a><br /><br/>
            <a className='subMenuItems' href='/#'>Google Advertising</a><br /><br/>
            <a className='subMenuItems' href='/#'>6 Advertising</a><br />
        </p>
    </>)
}
const AboutMenu = ({handleClosePopup}) => {
    
    return (
      <>
        <NavLink to='/careers' className="submenu-active" onClick={handleClosePopup}>Google Advertising</NavLink>
        <NavLink to='/contact-us' onClick={handleClosePopup}>Social Media Management</NavLink>
        <NavLink to='/seo' onClick={handleClosePopup}>Social Media Advertising</NavLink>
        <NavLink to='/about' className="about-link" onClick={handleClosePopup}>
          About Us
        </NavLink>
      </>
    );
  };

const PerformanceSubMenu = ({handleClosePopup}) => {
    const location = useLocation();
    return (<>
        <NavLink to='/google-ads-manangement' className={`${location.pathname.includes("/google-ads-manangement") && 'submenu-active'}`} onClick={handleClosePopup}>Google Advertising</NavLink>
        <NavLink to='/social-media-management' className={`${location.pathname.includes("/social-media-management") && 'submenu-active'}`} onClick={handleClosePopup}>Social Media Management</NavLink>
        <NavLink to='/social-media-advertisement' className={`${location.pathname.includes("/social-media-advertisement") && 'submenu-active'}`} onClick={handleClosePopup}>Social Media Advertising</NavLink>
        <NavLink to='/seo' className={`${location.pathname.includes("/seo") && 'submenu-active'}`} onClick={handleClosePopup}>Search Engine Optimisation</NavLink>
    </>
    );
};
const MainTextMenu = () => {
    return (<>
        <h2>Results</h2><h2 style={{marginTop:'-36px'}}> Begin Here.</h2>
        <p className='subHereLine'>Custom Strategies, Proven Results.</p>
    </>
    );
};
const NavMenu = ({ isOpen, className, children, setPopup }) => {
    const popupStyle = {
      display: isOpen ? 'flex' : 'none',
    //   height: 'calc(100vh - 70.25px)',
      height: '100%',
      minHeight: '800px',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    };
  
    const overlayStyle = {
      display: 'block',
    };
  

    return (
        <div>
            <div className={`overlay ${className}`} style={overlayStyle}>
                <div className={`popup `} style={popupStyle}>
                    {children}
                </div>
            </div>
        </div>
    );
};

NavMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    setPopup: PropTypes.func.isRequired,
};
const Navbar = () => {
    const [isPopup, setPopup] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const location = useLocation();
   
    const logoStyle = { height: '31px', width: '30px' };

    const handlePopup = () => {
        
        setPopup(!isPopup);
        if (!isPopup) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'auto';
          }
       

    };
    const handleClosePopup = () =>{
        setPopup(false);
        setActiveMenu(null);
        document.body.style.overflow = 'auto';
       
        
    }

    const addClassActive = (menu) => {
        if (activeMenu === menu) {
            setActiveMenu(null);
         
        } else {
            setActiveMenu(menu)
        }
    };

    useEffect(()=>{
        if(location.pathname === "/"){
            setActiveMenu(null)
        } else if (location.pathname.includes("/about")){
            setActiveMenu('subMenuDot2')
        } else if(location.pathname.includes("/google-ads-manangement") || location.pathname.includes("/social-media-management") || location.pathname.includes("/add") ||  location.pathname.includes("/seo") ){
            setActiveMenu('submenu1')
        } else if (location.pathname.includes("/web-design-and-development") || location.pathname.includes("/brand-and-design")){
            setActiveMenu('submenu2')
        } else if(location.pathname.includes("/careers")){
            setActiveMenu('submenu3')
        } else if(location.pathname.includes("/case-studies")){
            setActiveMenu('submenu4')
        } else if (location.pathname.includes("/it-managed-services-and-leading-it-support")){
            setActiveMenu('submenu5')
        }
    },[location])
  
    
    return (
        <>
            <div className={isPopup ? 'navbar dark' : ' navbar'}>
                <div className='nav'>
                    <span className='desktopLogo me-auto'>
                        <NavLink to='/' onClick={handleClosePopup}>
                            <MainLogo width={160} height={44}/>
                        </NavLink>
                    </span>
                    <span className='mobileLogo me-auto'>
                        <NavLink to='/' onClick={handleClosePopup}>
                            <MobileLogo/>
                        </NavLink>
                    </span>
                    <ul>
                        <li onClick={(e) => {
                            e.preventDefault();
                            handlePopup()
                        }}>
                            <a href='/'><span className='menu-text'>Menu</span>
                                <span>{isPopup ? <MenuBarOpen /> : <MenuBarClose />}</span>
                            </a>
                        </li>

                        <li>
                            <NavLink to="/contact-us" onClick={handleClosePopup}>
                                <span className='menu-text'>Contact us</span>
                                    <span className='span'>
                                        <ChatIcon />
                                    </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>

            <NavMenu isOpen={isPopup} className={isPopup ? 'slideIn' : 'slideOut'}  >

                <div className='outerCircle'>
                    <div className='topLeft'>
                        <div className={`subMenuDot1 ${activeMenu === null ? 'active' : ''}`}>
                            <div className='menuItems' onClick={() => addClassActive(null)}>
                                <NavLink to='/' className='d-flex text-decoration-none align-items-center'
                                onClick={handleClosePopup}>
                                    <h2 className='MenuHeading'> Home</h2>
                                    <div className='menuItemIcon'>
                                        <HomeIcon />
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className={`subMenuDot2 ${location.pathname.includes("/about") && "active"} ${activeMenu === 'subMenuDot2' ? 'active' : ''}`}>
                            <NavLink to="/about" className='menuItems text-decoration-none' onClick={handleClosePopup}>
                                <h2 className='MenuHeading'>about us</h2>
                                <div className='menuItemIcon'>
                                    <PeopleIcon />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className='topRight'>
                        <div className={`subMenuDot1 submenu1  ${activeMenu === 'submenu1' ? 'active' : ''}`}>
                            <div className='menuItems' onClick={() => addClassActive('submenu1')}>
                                <div className='menuItemIcon'>
                                    <SpeakerIcon />
                                </div>
                                <h2 className='MenuHeading'>Digital performance</h2>
                            </div>
                        </div>
                        <div className={`subMenuDot2 submenu2 ${activeMenu === 'submenu2' ? 'active' : ''}`}>
                            <div className='menuItems' onClick={() => addClassActive('submenu2')}>
                                <div className='menuItemIcon'>
                                    <ExperienceIcon />
                                </div>
                                <h2 className='MenuHeading'>User experience</h2>
                            </div>
                        </div>
                    </div>
                    <div className='bottomLeft '>
                        <div className={`subMenuDot1 submenu3 ${activeMenu === 'submenu3' ? 'active' : ''}`}>
                            <NavLink to="/careers" className='menuItems text-decoration-none' onClick={handleClosePopup}>
                                <h2 className='MenuHeading'>Careers</h2>
                                <div className='menuItemIcon'>
                                    <WorkIcon />
                                </div>
                            </NavLink>
                            {/* <div className='menuItems' onClick={() => addClassActive('submenu3')}>
                                <h2 className='MenuHeading'>Careers</h2>
                                <div className='menuItemIcon'>
                                    <WorkIcon />
                                </div>
                            </div> */}
                        </div>
                        <div className={`subMenuDot2 submenu4  ${activeMenu === 'submenu4' ? 'active' : ''}`}>
                            <NavLink to="/case-studies" className='menuItems text-decoration-none' onClick={handleClosePopup}>
                                <h2 className='MenuHeading'>Case Studies</h2>
                                <div className='menuItemIcon'>
                                    <CaseStudiesIcon />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className='bottomRight'>
                        <div className={`subMenuDot1 submenu5 ${activeMenu === 'submenu5' ? 'active' : ''}`}>
                            <NavLink to="/it-managed-services-and-leading-it-support" className='menuItems text-decoration-none' onClick={handleClosePopup}>
                                <div className='menuItemIcon'>
                                    <BusinessIcon />
                                </div>
                                <h2 className='MenuHeading'>Business IT</h2>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='centerText text-light'>
                    <div className={`subMenuItems ${activeMenu === 'submenu1' || activeMenu === 'submenu2' ? '' : 'show'}`}>
                        <MainTextMenu />
                    </div>
                    {/* <div className={`subMenuItems ${activeMenu === 'subMenuDot2' ? 'show' : ''}`}>
                        <AboutMenu  handleClosePopup={handleClosePopup}/>
                    </div> */}
                    <div className={`subMenuItems ${activeMenu === 'submenu1' ? 'show ' : ''}`}>
                        <PerformanceSubMenu handleClosePopup={handleClosePopup} />
                    </div>
                    <div className={`subMenuItems ${activeMenu === 'submenu2' ? 'show' : ''}`}>
                        <NavLink to='/web-design-and-development' className={`${location.pathname.includes("/web-design-and-development") && 'submenu-active'}`} onClick={handleClosePopup} >Website Design & Development</NavLink>
                        <NavLink to='/brand-and-design' className={`${location.pathname.includes("/brand-and-design") && 'submenu-active'}`} onClick={handleClosePopup}>Branding & Logo Design</NavLink>
                        
                    </div>
                    {/* <div className={`subMenuItems ${activeMenu === 'submenu3' ? 'show' : ''}`}>
                        <NavLink to='/case' onClick={handleClosePopup} >Website Design & Development</NavLink>
                        <NavLink to='/social-media-management' onClick={handleClosePopup}>Social Media Management</NavLink>
                        <NavLink to='/google-ads-manangement' onClick={handleClosePopup}>Social Media Advertising</NavLink>
                        <NavLink to='/careers' onClick={handleClosePopup}>Careers</NavLink>
                    </div>
                    <div className={`subMenuItems ${activeMenu === 'submenu' ? 'show' : ''}`}>
                        <NavLink to='/user' onClick={handleClosePopup} >Google Advertising </NavLink>
                        <NavLink to='/web-design-and-development' onClick={handleClosePopup}>Social Media Management</NavLink>
                        <NavLink to='/web-design-and-development' onClick={handleClosePopup}>Social Media Advertising</NavLink>
                        <NavLink to='/case-studies' onClick={handleClosePopup}>Case Studies</NavLink>
                    </div>
                    <div className={`subMenuItems ${activeMenu === 'submenu' ? 'show' : ''}`}>
                        <a href='/#' >Website Design & Development</a>
                        <NavLink to='/it-managed-services-and-leading-it-support' onClick={handleClosePopup}>Business IT</NavLink>
                    </div> */}
                </div>
                <div className='mobileMenuDiv'>
                    <MobileMenu handleClosePopup={handleClosePopup}/>
                </div>
            </NavMenu>




        </>
    )
}

export default Navbar